text-align: center;

#Navigation {
}

details > summary {display:inline-block}

summary:hover {
  background-color: #0056b3;
}

#qualifications > *:not(summary) {
  padding-left: 50px; /* Add padding to elements inside details, except the summary */
}

.summary-content {
  position: relative;
  display: inline-block; /* Make sure the underline adjusts to the text width */
  cursor: pointer; /* Optional: makes it clear that summary is clickable */
}

.summary-content::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px; /* Adjust underline thickness */
  background-color: white; /* Adjust underline color */
  transition: width 0.3s ease-in-out;
}

.summary-content:hover::after {
  width: 100%;
}

summary:active {
  background-color: #004085;
  transform: scale(0.98);
}

details[open] summary {
  /* background-color: #0062cc; */
}

details[open] summary:hover {
  background-color: #004085;
}

summary h2, summary b {
  display: inline; /* Change the h2 to behave as inline, so it doesn't take up the whole line */
  margin: 0; /* Optional: remove extra margin if needed */
}

/* Add animation to the details content */
details[open] > *:not(summary) {
  animation: slideDown 0.3s ease-in-out;
  opacity: 1;
}

details > *:not(summary) {
  max-height: 0; /* Start with zero height */
  opacity: 0; /* Start fully transparent */
  overflow: hidden; /* Hide content outside the bounds */
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out; /* Smooth transition */
}

details[open] > *:not(summary) {
  max-height: 100%; /* Set to a large enough height for the content */
  opacity: 1; /* Fully visible when open */
}

/* Add a sliding animation */
@keyframes slideDown {
  from {
    max-height: 0;
    opacity: 0;
  }
  to {
    max-height: 100vh; /* Match the value for opened content */
    opacity: 1;
  }
}

button {
  margin: 0px;
}

.button_link {
  margin: 5px;
}

.flex-container {
  display: flex;
  flex-flow: row wrap;
}

img {
  max-height: 1000px;
  padding: 10px;
  max-width: 100%;
}

br {
  margin: 0px;
}

.images {
  max-height: 500px;
  padding: 10px;
  max-width: 100%;
}

.list {
  justify-content: left;
  align-items: left;
  text-align: left;
  margin: 5px;
}

.left, .summary-content {
  justify-content: left;
  align-items: left;
  text-align: left;
  margin: 0 auto;
}

.larger, .larger2 {
  font-size: 1.2em;
  margin: 0px;
}

tab {
  display: inline-block;
  margin-left: 15px;
}

* {
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 5px;
  /* border: 3px solid green;
  padding: 10px; */
}

list::marker {
  vertical-align: top;
}

p {
  justify-content: left;
  align-items: left;
  text-align: left;
}

/*
p.indent {
  margin-left: 80px;
}
*/

.App {
  max-width: 1200px;
  width: 100%;
}

summary {
  display: inline-block;
  padding: 8px 0px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  /* background-color: #007BFF; */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}



